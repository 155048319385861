import '../styles/B2B.scss'
import 'swiper/components/pagination'
import 'swiper/swiper.scss'
import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import SEO, { getSeoMedia } from '../atoms/SEO'
import Swiper from 'react-id-swiper'
import SwiperCore, { Pagination } from 'swiper'
import { ReactComponent as ArrowGraph } from '../assets/img/icons/arrow-graph.svg'
import { B2BCommentsList } from '../organisms/B2BCommentsList'
import { BreadCrumbs, BreadcrumbSEO } from '../molecules/BreadCrumbs'
import { Button } from '../atoms/Button'
import { Event as GAEvent } from '../components/GA'
import { WeWillHelpSection } from '../organisms/WeWillHelpSection'
import { selectDeviceSize } from '../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

SwiperCore.use([Pagination])

const trustArr = [
  {
    img: '/img/trust/currency-com.svg',
    alt: 'Логотип Currency'
  },
  {
    img: '/img/trust/globalports.svg',
    alt: 'Логотип Global Ports'
  },
  {
    img: '/img/trust/nexters.png',
    srcSet: '/img/trust/nexters@2x.png, /img/trust/nexters@3x.png',
    alt: 'Логотип Nexters'
  },
  {
    img: '/img/trust/setters.svg',
    alt: 'Логотип Setters'
  },
  {
    img: '/img/trust/techopark.png',
    srcSet: '/img/trust/techopark@2x.png, /img/trust/techopark@3x.png',
    alt: 'Логотип Технопарк'
  },
  {
    img: '/img/trust/asian-brand.png',
    alt: 'Логотип Asian Brand'
  }
]

export const B2bSubmitButton = (label, className) => {
  const clickUpFormUrl =
    'https://forms.clickup.com/f/hfk7y-2381/1JQEYUMHF0KFTGT7I6'
  const handleNewOpenFormB2b = useCallback((label) => {
    GAEvent.NewOpenFormB2B(label)
  }, [])
  return (
    <Button.NewPrimary
      className={className}
      href={clickUpFormUrl}
      onClick={() => {
        handleNewOpenFormB2b(label)
      }}
      rel="noreferrer"
      size="large"
      target="_blank"
      type="externalLink"
      width="fullScreen"
    >
      Оставить заявку
    </Button.NewPrimary>
  )
}

const B2B = () => {
  const isDocument = () => typeof document !== 'undefined'
  let root

  useEffect(() => {
    root = document.querySelector('.root')
  }, [])

  useEffect(() => {
    if (isDocument()) {
      root.scrollTo(0, 0)
      root.classList.remove('root_fixed')
    }
  }, [root])

  useEffect(() => {
    if (root) {
      root.scrollTo(0, 0)
      root.classList.remove('root_fixed')
    }
  }, [root])
  const wWidth = useSelector(selectDeviceSize)
  const paginationRef = useRef(null)

  const hadleOpenLink = useCallback((label) => {
    GAEvent.OpenLink(label)
  }, [])

  const params = {
    loop: false,
    grabCursor: true,
    pagination: { clickable: true, type: 'bullets', el: paginationRef.current },
    slidesPerView: 'auto',
    spaceBetween: 0,
    preventClicks: false,
    preventClicksPropagation: false,
    on: {
      beforeInit: ({ params }) => {
        params.pagination.el = paginationRef.current
      }
    }
  }

  return (
    <>
      <App>
        <SEO
          children={<BreadcrumbSEO />}
          description="Психологический сервис для ваших сотрудников ✔Вебинары и памятки по психическому здоровью на работе ✔Персонализированный подбор формата поддержки для вашей команды."
          images={getSeoMedia([{ pathname: '/img/og/b2b.png', alt: 'b2b' }])}
          title="Психологическая поддержка для сотрудников — консультации психолога онлайн | YouTalk"
        />
        <Header hideBtn />
        <BreadCrumbs />
        <main className="main">
          <section className="b2b-top">
            <div className="container">
              <div className="row">
                <div className="designer-col col-lg-12">
                  <div className="b2b-top__wrap b2b-top-wrap">
                    <h1 className="b2b-top-wrap__title">
                      Поддержите каждого человека в&nbsp;своей команде
                    </h1>
                    <p className="b2b-top-wrap__text">
                      Запустите программу психологической поддержки сотрудников
                    </p>
                    <div className="b2b-top-wrap__img-wrap">
                      <img
                        alt="Изображение трех людей на спортивной эстафете.
                          Слева мужчина с протянутой рукой, посередине девушка протягивает палочку девушке,
                          стоящей справа на веревочной лестнице"
                        src="/img/icons/home-decor/home-decor2.svg"
                      />
                    </div>
                    {B2bSubmitButton('firstScreen')}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="trust">
            <div className="container">
              <div className="row">
                <div className="designer-col col-lg-12">
                  <h2 className="trust__title">Нам доверяют</h2>
                  <ul className="trust__logos trust-logos">
                    {trustArr.map(({ img, alt, srcSet = null }, i) => (
                      <li key={i} className="trust-logos__item">
                        <img alt={alt} src={img} srcSet={srcSet} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="b2b-comments">
            <div className="container">
              <div className="row">
                <div className="designer-col col-lg-12">
                  <div className="b2b-comments__list-wrap">
                    <ul className="b2b-comments-list">
                      <B2BCommentsList
                        swiper={useMemo(() => wWidth < 768, [wWidth])}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <img
              alt="Светло-синяя фигура"
              className="b2b-comments__decor"
              src="/img/comments/decor.svg"
            />
          </section>

          <section className="support-program">
            <div className="container">
              <div className="row">
                <div className="designer-col col-lg-12">
                  <h2 className="support-program__title h1">
                    Программа поддержки
                  </h2>

                  {wWidth < 768 ? (
                    <div className="support-program__sl-wrap sl-wrap">
                      <Swiper
                        {...params}
                        containerClass="sl-wrap__sl support-program-sl"
                      >
                        <div className="support-program-sl__item support-program-sl-item">
                          <div className="support-program-sl-item__img-wrap">
                            <img
                              alt="Схематичное изображение графиков, диаграмм и документов"
                              src="/img/icons/support/support.svg"
                            />
                          </div>
                          <h3 className="support-program-sl-item__name h5">
                            Психологическое образование
                          </h3>
                          <p className="support-program-sl-item__info">
                            Вебинары, памятки,
                            <br />
                            материалы для рассылки
                          </p>
                        </div>
                        <div className="support-program-sl__item support-program-sl-item">
                          <div className="support-program-sl-item__img-wrap">
                            <img
                              alt="Рисунок открытого на телефоне чата с аватаркой девушки"
                              src="/img/icons/support/support2.svg"
                            />
                          </div>
                          <h3 className="support-program-sl-item__name h5">
                            Консультации
                          </h3>
                          <p className="support-program-sl-item__info">
                            Общение с психологом в&nbsp;формате видео или
                            переписки
                          </p>
                        </div>
                        <div className="support-program-sl__item support-program-sl-item">
                          <div className="support-program-sl-item__img-wrap">
                            <img
                              alt="Схематичное изображение видеолекции и заметок"
                              src="/img/icons/support/support3.svg"
                            />
                          </div>
                          <h3 className="support-program-sl-item__name h5">
                            Бонус для HR
                          </h3>
                          <p className="support-program-sl-item__info">
                            Обезличенные отчёты о полученных консультациях и
                            темах запросов
                          </p>
                        </div>
                      </Swiper>
                      <div
                        ref={paginationRef}
                        className="sl-wrap__dots sl-dots"
                      ></div>
                      {B2bSubmitButton('support', 'sl-wrap__btn')}
                    </div>
                  ) : (
                    <div className="support-program__sl-wrap sl-wrap">
                      <div className="sl-wrap__sl support-program-sl">
                        <div className="support-program-sl__item support-program-sl-item">
                          <div className="support-program-sl-item__img-wrap">
                            <img alt="" src="/img/icons/support/support.svg" />
                          </div>
                          <h3 className="support-program-sl-item__name h5">
                            Психологическое образование
                          </h3>
                          <p className="support-program-sl-item__info">
                            Вебинары, памятки, <br />
                            материалы для рассылки
                          </p>
                        </div>
                        <div className="support-program-sl__item support-program-sl-item">
                          <div className="support-program-sl-item__img-wrap">
                            <img alt="" src="/img/icons/support/support2.svg" />
                          </div>
                          <h3 className="support-program-sl-item__name h5">
                            Консультации
                          </h3>
                          <p className="support-program-sl-item__info">
                            Общение с психологом в&nbsp;формате видео или
                            переписки
                          </p>
                        </div>
                        <div className="support-program-sl__item support-program-sl-item">
                          <div className="support-program-sl-item__img-wrap">
                            <img alt="" src="/img/icons/support/support3.svg" />
                          </div>
                          <h3 className="support-program-sl-item__name h5">
                            Бонус для HR
                          </h3>
                          <p className="support-program-sl-item__info">
                            Обезличенные отчеты о полученных консультациях и
                            темах запросов
                          </p>
                        </div>
                      </div>
                      {B2bSubmitButton('support', 'sl-wrap__btn')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="stress-statistics">
            <div className="container">
              <div className="row">
                <div className="designer-col col-lg-12">
                  <h2 className="stress-statistics__title h1">
                    <span>83% сотрудников</span>
                    <br />
                    страдают от стресса, связанного{wWidth < 414 && <br />}{' '}
                    с&nbsp;работой
                  </h2>
                  <p className="stress-statistics__source source">
                    по данным{' '}
                    <a
                      href="https://www.stress.org/42-worrying-workplace-stress-statistics"
                      onClick={() => hadleOpenLink('stress')}
                      rel="noreferrer"
                      target="_blank"
                    >
                      American Institute of Stress
                    </a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="designer-col col-12 col-sm-2 col-md-3 col-lg-3">
                  <div className="stress-statistics__col stress-statistics-col">
                    {wWidth > 767 && (
                      <div className="stress-statistics-col__desc stress-statistics-col__desc_1 desc-with-img desc-with-img_w18">
                        <img
                          alt="Иконка песочных часов"
                          src="/img/b2b/1.png"
                          srcSet="/img/b2b/1@2x.png 2x, /img/b2b/1@3x.png 3x"
                        />
                        <span>Смена настроения</span>
                      </div>
                    )}
                    <div className="stress-statistics-col__desc stress-statistics-col__desc_2 desc-with-img desc-with-img_w18">
                      <img
                        alt="Иконка бомбы"
                        src="/img/b2b/2.png"
                        srcSet="/img/b2b/2@2x.png 2x, /img/b2b/2@3x.png 3x"
                      />
                      <span>Прокрастинация</span>
                    </div>
                    <div className="stress-statistics-col__desc stress-statistics-col__desc_3 desc-with-img desc-with-img_w18">
                      <img
                        alt="Иконка театральных масок"
                        src="/img/b2b/3.png"
                        srcSet="/img/b2b/3@2x.png 2x, /img/b2b/3@3x.png 3x"
                      />
                      <span>Конфликты</span>
                    </div>
                  </div>
                </div>
                <div className="designer-col col-12 col-sm-8 col-md-6 col-lg-6">
                  <img
                    alt="Уставшая девушка спит на столе, накрытая бумагами"
                    className="stress-statistics__decor-img"
                    src="/img/tired-girl.png"
                    srcSet="/img/tired-girl@2x.png 2x, /img/tired-girl@3x.png 3x"
                  />
                </div>
                <div className="col-sm-2 col-md-3 col-lg-3">
                  <div className="stress-statistics__col stress-statistics-col">
                    <div className="stress-statistics-col__desc stress-statistics-col__desc_4 desc-with-img desc-with-img_w18">
                      <img
                        alt="Иконка дизлайка"
                        src="/img/b2b/4.png"
                        srcSet="/img/b2b/4@2x.png 2x, /img/b2b/4@3x.png 3x"
                      />
                      <span>Тревога</span>
                    </div>
                    <div className="stress-statistics-col__desc stress-statistics-col__desc_5 desc-with-img desc-with-img_w18">
                      <img
                        alt="Иконка грустного смайлика"
                        src="/img/b2b/5.png"
                        srcSet="/img/b2b/5@2x.png 2x, /img/b2b/5@3x.png 3x"
                      />
                      <span>Депрессия</span>
                    </div>
                    {wWidth > 767 && (
                      <>
                        <div className="stress-statistics-col__desc stress-statistics-col__desc_6 desc-with-img desc-with-img_w18">
                          {' '}
                          <img
                            alt="Иконка спортсмена, поднимающего штангу"
                            src="/img/b2b/6.png"
                            srcSet="/img/b2b/6@2x.png 2x, /img/b2b/6@3x.png 3x"
                          />
                          <span>Нагрузка</span>
                        </div>
                        <div className="stress-statistics-col__desc stress-statistics-col__desc_7 desc-with-img desc-with-img_w18">
                          <img
                            alt="Иконка сигнального фонаря"
                            src="/img/b2b/7.png"
                            srcSet="/img/b2b/7@2x.png 2x, /img/b2b/7@3x.png 3x"
                          />
                          <span>Снижение мотивации</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <img
              alt="Светло-серая фигура"
              className="stress-statistics__top-decor"
              src="/img/stress/top-decor.svg"
            />
            <img
              alt="Светло-серая фигура"
              className="stress-statistics__bottom-decor"
              src="/img/stress/bottom-decor.svg"
            />
          </section>

          <section className="plusses">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <h2 className="plusses__title h1">
                    Сотрудникам будет просто
                  </h2>
                  <img
                    alt="Изображение телефона с открытым диалогом с администратором YouTalk"
                    className="plusses__img"
                    src="/img/decor-hand.png"
                    srcSet="/img/decor-hand@2x.png, /img/decor-hand@3x.png"
                  />
                  <ul className="plusses__list plusses-list">
                    <li className="plusses-list__item">
                      Общаться с психологом из дома,
                      офиса&nbsp;или&nbsp;в&nbsp;пути
                    </li>
                    <li className="plusses-list__item">
                      Выбирать формат консультаций
                    </li>
                    <li className="plusses-list__item">
                      Чувствовать себя в безопасности
                    </li>
                    <li className="plusses-list__item">
                      Заботиться о своём ментальном здоровье
                    </li>
                  </ul>
                  {B2bSubmitButton('employee', 'plusses__btn')}
                </div>
              </div>
            </div>
            {wWidth > 767 && (
              <img
                alt="Зеленый полукруг"
                className="plusses__decor-img"
                src="/img/icons/plusses-decor.svg"
              />
            )}
          </section>

          <section className="what-affects">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <div className="what-affects__head">
                    <h2 className="what-affects__title h1">На что влияет</h2>
                    <p className="what-affects__desc">
                      Программы психологической поддержки помогают людям быть
                      продуктивными и&nbsp;вовлечёнными
                    </p>
                  </div>
                </div>

                <div className="designer-col col-12">
                  <div className="affects-graphs">
                    <div className="affects-graphs__row affects-graphs-row">
                      <div className="affects-graphs-row__col affects-graphs-col">
                        <div className="affects-graphs-col__row">
                          <div className="affects-graphs-col__block affects-graph affects-graph_white">
                            <div className="affects-graph__rect affects-graph__rect_h128"></div>
                          </div>
                          <div className="affects-graphs-col__block affects-graph affects-graph_green">
                            <div className="affects-graph__percent h2">
                              <span>+ 21%</span>
                              <ArrowGraph />
                            </div>
                            <div className="affects-graph__rect affects-graph__rect_h250"></div>
                          </div>
                        </div>
                        <p className="affects-graphs-col__desc h6">
                          Удовлетворённость
                          <br /> жизнью
                        </p>
                      </div>
                      <div className="affects-graphs-row__col affects-graphs-col affects-graphs-col_2">
                        <div className="affects-graphs-col__row">
                          <div className="affects-graphs-col__block affects-graph affects-graph_white">
                            <div className="affects-graph__rect affects-graph__rect_h121"></div>
                          </div>
                          <div className="affects-graphs-col__block affects-graph affects-graph_green">
                            <div className="affects-graph__percent h2">
                              <span>+ 10%</span>
                              <ArrowGraph />
                            </div>
                            <div className="affects-graph__rect affects-graph__rect_h168"></div>
                          </div>
                        </div>
                        <p className="affects-graphs-col__desc h6">
                          Вовлечённость
                          <br /> в работу
                        </p>
                      </div>
                    </div>
                    <div className="affects-graphs__row affects-graphs-row">
                      <div className="affects-graphs-row__col affects-graphs-col">
                        <div className="affects-graphs-col__block affects-graph affects-graph_blue affects-graph_blue_1">
                          <div className="affects-graph__percent h2">
                            <span>- 15%</span>
                            <ArrowGraph />
                          </div>
                          <div className="affects-graph__rect affects-graph__rect_h180"></div>
                        </div>
                        <p className="affects-graphs-col__desc h6">
                          Пропуски
                          <br />
                          работы
                        </p>
                      </div>
                      <div className="affects-graphs-row__col affects-graphs-col">
                        <div className="affects-graphs-col__block affects-graph affects-graph_blue affects-graph_blue_2">
                          <div className="affects-graph__percent h2">
                            <span>- 13%</span>
                            <ArrowGraph />
                          </div>
                          <div className="affects-graph__rect affects-graph__rect_h128"></div>
                        </div>
                        <p className="affects-graphs-col__desc h6">
                          Стресс
                          <br /> на работе
                        </p>
                      </div>
                      <div className="affects-graphs-row__col affects-graphs-col">
                        <div className="affects-graphs-col__block affects-graph affects-graph_blue affects-graph_blue_3">
                          <div className="affects-graph__percent h2">
                            <span>- 28%</span>
                            <ArrowGraph />
                          </div>
                          <div className="affects-graph__rect affects-graph__rect_h250"></div>
                        </div>
                        <p className="affects-graphs-col__desc h6">
                          Презентеизм
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img
              alt="Светло-зеленая фигура"
              className="what-affects__decor"
              src="/img/what-affects/decor.svg"
            />
          </section>
          <WeWillHelpSection />
        </main>
        <Footer />
      </App>
    </>
  )
}

export default B2B
